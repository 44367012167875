import * as React from 'react';
import { useEffect, useMemo } from 'react';

import {
  Box,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TableRowProps,
  Tbody,
  Td as RawTd,
  Text,
  Th as RawTh,
  Thead,
  Tr as RawTr,
  useBoolean,
  IconButton,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import OfferStore from '../../../../stores/offerStore';
import TableLoader from '../../../../shared/ui/Table/TableLoader';
import TableNoData from '../../../../shared/ui/Table/TableNoData';
import TableError from '../../../../shared/ui/Table/TableError';
import { IIdNameCode } from '../../../../shared/model';
import { IVedicScore } from '../../../../shared/model/offers';
import { PlatformIcon } from '../../../../shared/ui/PlatformIcon';

const Tr = ({ children, ...rest }: TableRowProps) => {
  return (
    <RawTr _hover={{ borderBottom: '1px solid', borderColor: 'gray.200' }} {...rest}>
      {children}
    </RawTr>
  );
};
const Td = ({ children, ...rest }: TableCellProps) => {
  return (
    <RawTd sx={{ textWrap: 'pretty' }} {...rest}>
      {children}
    </RawTd>
  );
};

const Th = ({ children, ...rest }: TableColumnHeaderProps) => {
  return (
    <RawTh sx={{ textWrap: 'pretty' }} {...rest}>
      {children}
    </RawTh>
  );
};

export interface IOfferDetailsProps {
  offerId: number;
}

const OfferDetails = ({ offerId, ...rest }: IOfferDetailsProps) => {
  const { offer, fetchOffer, loading, error } = OfferStore();
  const [showVedicCompatibility, setShowVedicCompatibility] = useBoolean(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (offerId) fetchOffer(offerId);
  }, [offerId, fetchOffer]);

  const COLUMN_AMOUNT = 4;

  const renderBody = useMemo(() => {
    const renderVedicCompatibility = () => {
      const { vedic } = offer.compatibility;
      const vedicKeys = Object.keys(vedic.scores) as (keyof IVedicScore)[];

      return (
        <>
          {vedicKeys.map(key => (
            <Tr key={`row-${key.toString()}`}>
              <Th fontSize="68%" fontStyle="italic" pl={12}>
                &nbsp;{t(`offer:${key.toString()}`)}
              </Th>
              <Td></Td>
              <Td></Td>
              <Td>{vedic.scores[key].value}</Td>
            </Tr>
          ))}
        </>
      );
    };

    const renderData = () => {
      const { candidate, compatibility, searcher } = offer;
      const arrayToString = (arr: IIdNameCode[]) => arr ? arr.map(item => item.name).join(', ') : '';

      console.log('offer', offer);

      return (
        <Tbody>
          <Tr>
            <Th>
              <Box display="flex" justifyContent="space-between">
                <Text>1. Астрологическая совместимость 18-36 баллов</Text>
                <IconButton
                  size="sm"
                  aria-label={showVedicCompatibility ? 'Collapse' : 'Expand'}
                  onClick={setShowVedicCompatibility.toggle}
                  icon={showVedicCompatibility ? <MdExpandLess /> : <MdExpandMore />}
                />
              </Box>
            </Th>
            <Td></Td>
            <Td></Td>
            <Td>{compatibility.vedic.value}</Td>
          </Tr>
          {showVedicCompatibility && renderVedicCompatibility()}
          <Tr>
            <Th>2. Расстояние</Th>
            <Td></Td>
            <Td></Td>
            <Td>{compatibility.distance.value}</Td>
          </Tr>
          <Tr>
            <Th>3. Интересы</Th>
            <Td>{arrayToString(searcher.profile.interests)}</Td>
            <Td>{arrayToString(candidate.profile.interests)}</Td>
            <Td>{compatibility.interests.value}</Td>
          </Tr>
          <Tr>
            <Th>4. Образование</Th>
            <Td>{searcher.profile.education?.name}</Td>
            <Td>{candidate.profile.education?.name}</Td>
            <Td>{compatibility.education.value}</Td>
          </Tr>
          <Tr>
            <Th>5.Вероисповедание</Th>
            <Td>{searcher.profile.religion?.name}</Td>
            <Td>{candidate.profile.religion?.name}</Td>
            <Td>{compatibility.religion.value}</Td>
          </Tr>
          <Tr>
            <Th>6.Профессиональный статус</Th>
            <Td>{searcher.profile.professionalStatus?.name}</Td>
            <Td>{candidate.profile.professionalStatus?.name}</Td>
            <Td>{compatibility.professionalStatus.value}</Td>
          </Tr>
          <Tr>
            <Th>7.Планы на семью</Th>
            <Td>{searcher.profile.familyPlan?.name}</Td>
            <Td>{candidate.profile.familyPlan?.name}</Td>
            <Td>{compatibility.familyPlan.value}</Td>
          </Tr>
          <Tr>
            <Th>8. Вакцина от COVID</Th>
            <Td>{searcher.profile.covid?.name}</Td>
            <Td>{candidate.profile.covid?.name}</Td>
            <Td>{compatibility.covid.value}</Td>
          </Tr>
          <Tr>
            <Th>9.Курение</Th>
            <Td>{searcher.profile.smokeStatus?.name}</Td>
            <Td>{candidate.profile.smokeStatus?.name}</Td>
            <Td>{compatibility.smokeStatus.value}</Td>
          </Tr>
          <Tr>
            <Th>10.Алкоголь</Th>
            <Td>{searcher.profile.alcoholStatus?.name}</Td>
            <Td>{candidate.profile.alcoholStatus?.name}</Td>
            <Td>{compatibility.alcoholStatus.value}</Td>
          </Tr>
          <Tr>
            <Th>11. Предпочтения в еде</Th>
            <Td>{searcher.profile.foodPreference?.name}</Td>
            <Td>{candidate.profile.foodPreference?.name}</Td>
            <Td>{compatibility.foodPreference.value}</Td>
          </Tr>
          <Tr>
            <Th>12.Тренировки</Th>
            <Td>{searcher.profile.workout?.name}</Td>
            <Td>{candidate.profile.workout?.name}</Td>
            <Td>{compatibility.workout.value}</Td>
          </Tr>
          <Tr>
            <Th>13.Привычки во сне</Th>
            <Td>{searcher.profile.sleepHabit?.name}</Td>
            <Td>{candidate.profile.sleepHabit?.name}</Td>
            <Td>{compatibility.sleepHabit.value}</Td>
          </Tr>
          <Tr>
            <Th>14.Питомцы</Th>
            <Td>{arrayToString(searcher.profile.pets)}</Td>
            <Td>{arrayToString(candidate.profile.pets)}</Td>
            <Td>{compatibility.pets.value}</Td>
          </Tr>
          <Tr>
            <Th>15. Идеальное место встречи</Th>
            <Td>{arrayToString(searcher.profile.idealMeetingPoints)}</Td>
            <Td>{arrayToString(candidate.profile.idealMeetingPoints)}</Td>
            <Td>{compatibility.idealMeetingPoints.value}</Td>
          </Tr>
          <Tr>
            <Th>16.Профиль заполнен на 100%</Th>
            <Td>{searcher.profile.filling}%</Td>
            <Td>{candidate.profile.filling}%</Td>
            <Td>{compatibility.fillingProfile.value}</Td>
          </Tr>
          <Tr>
            <Th>17.Платформа (ОС)</Th>
            <Td>
              <PlatformIcon os={searcher.os} />
            </Td>
            <Td>
              <PlatformIcon os={candidate.os} />
            </Td>
            <Td>{compatibility.os.value}</Td>
          </Tr>
          <Tr>
            <Th>18.Каста</Th>
            <Td>{searcher.searcher.caste}</Td>
            <Td>{candidate.searcher.caste}</Td>
            <Td>{compatibility.caste.value}</Td>
          </Tr>
          <Tr>
            <Th>19.Наличие свиданий</Th>
            <Td>{searcher.balance}</Td>
            <Td>{candidate.balance}</Td>
            <Td>{compatibility.hasBalance.value}</Td>
          </Tr>
          <Tr>
            <Th>20.Покупки в приложении</Th>
            <Td>todo</Td>
            <Td>todo</Td>
            <Td>{compatibility.hasPurchases.value}</Td>
          </Tr>
          <Tr>
            <Th>21.Возраст</Th>
            <Td>{searcher.searcher.age}</Td>
            <Td>{candidate.searcher.age}</Td>
            <Td>{compatibility.age.value}</Td>
          </Tr>
        </Tbody>
      );
    };

    if (loading) {
      return <TableLoader columnsLength={COLUMN_AMOUNT} />;
    }
    if (error) return <TableError columnsLength={COLUMN_AMOUNT} />;
    if (isEmpty(offer)) {
      return <TableNoData columnsLength={COLUMN_AMOUNT} />;
    } else {
      return renderData();
    }
  }, [loading, error, offerId, offer, setShowVedicCompatibility.toggle, showVedicCompatibility, t]);

  return (
    <TableContainer>
      <Table size="sm" variant="striped" {...rest}>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>
              Ответ
              <br /> пользователя
            </Th>
            <Th>
              Ответ
              <br /> кандидата
            </Th>
            <Th>Баллы</Th>
          </Tr>
        </Thead>
        {renderBody ?? null}
      </Table>
    </TableContainer>
  );
};

export default OfferDetails;
