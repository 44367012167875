import { useEffect, useMemo, useState } from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { IUser } from 'shared/model';

import userStore from '../../../../stores/userStore';
import UserShortOfferTable from '../components/UserShortOffersTable';
import { PairsFilterBar } from '../components/PairsFilterBar';

interface IUserProfileTabProps {
  user: IUser;
}

export function UserOffersTab({ user }: IUserProfileTabProps) {
  const { shortOffersList, loadingShortOffersList, errorShortOffersList, fetchShortOffersList, selectedBatch, batchDataLoading } = userStore();
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    fetchShortOffersList(user.userId, currentPage);
  }, [user.userId, currentPage, fetchShortOffersList]);

  const changePage = (page: number) => {
    setCurrentPage(page);
    fetchShortOffersList(user.userId, page);
  };

  const formattedOffersFromBatch = useMemo(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    return !isEmpty(selectedBatch) ? { items: selectedBatch.offers, pagination: { currentPage: 1, lastPage: 1, perPage: 100, total: 1 } } : null;
  }, [selectedBatch]);

  if (loadingShortOffersList) {
    return <p>Loading...</p>;
  }

  if (errorShortOffersList) {
    return <p>Error :( </p>;
  }

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr',
        lg: '1fr',
        '2xl': '1fr',
      }}
      gap={{
        base: '20px',
        xl: '20px',
      }}
    >
      <PairsFilterBar />
      <GridItem colSpan={{ base: 1, lg: 1 }}>
        <UserShortOfferTable
          tableData={formattedOffersFromBatch ? formattedOffersFromBatch : shortOffersList}
          loading={batchDataLoading || loadingShortOffersList}
          error={errorShortOffersList}
          changePage={changePage}
          changePerPage={() => false}
        />
      </GridItem>
    </Grid>
  );
}

export default UserOffersTab;
