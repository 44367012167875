import { Dispatch, SetStateAction, useState } from 'react';

import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { t } from 'i18next';

import useUsersStore from '../../../../stores/usersStore';

export const TextFilters = () => {
  const { filter, loading, setFilter } = useUsersStore();

  const [userId, setUserId] = useState(filter.userId || '');
  const [firstName, setFirstName] = useState(filter.firstName || '');
  const [email, setEmail] = useState(filter.email || '');
  const [cityOfLiving, setCityOfLiving] = useState(filter.locationCity || '');
  const [locationCountry, setLocationCountry] = useState(filter.locationCountry || '');
  const [createdAtFrom, setCreatedAtFrom] = useState(filter.createdAtFrom || '');
  const [createdAtTo, setCreatedAtTo] = useState(filter.createdAtTo || '');

  const handleInputClear = (setter: Dispatch<SetStateAction<string>>) => () => setter('');
  
  const handleSearchAll = () => {
    setFilter({
      userId,
      firstName,
      email,
      locationCity: cityOfLiving,
      locationCountry,
      createdAtFrom,
      createdAtTo,
    });
  };

  const handleResetAll = () => {
    setUserId('');
    setFirstName('');
    setEmail('');
    setCityOfLiving('');
    setLocationCountry('');
    setCreatedAtFrom('');
    setCreatedAtTo('');
    setFilter({});
  };

  return (
    <>
      <Box display="flex">
        <InputGroup size="md" mr="10px">
          <Input
            placeholder="User ID"
            disabled={loading}
            value={userId}
            onChange={e => setUserId(e.target.value)}
          />
          <InputRightElement>
            <Button size="sm" variant="plain" onClick={handleInputClear(setUserId)} isLoading={loading}>
              <SmallCloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>

        <InputGroup size="md" mr="10px">
          <Input
            placeholder="Имя пользователя"
            disabled={loading}
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <InputRightElement>
            <Button size="sm" variant="plain" onClick={handleInputClear(setFirstName)} isLoading={loading}>
              <SmallCloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>

        <InputGroup size="md">
          <Input
            placeholder="Email"
            disabled={loading}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <InputRightElement>
            <Button size="sm" variant="plain" onClick={handleInputClear(setEmail)} isLoading={loading}>
              <SmallCloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <br />
      <Box display="flex" mb={2}>Местоположение</Box>
      <Box display="flex">
        <InputGroup size="md" mr="10px">
          <Input
            placeholder={t('common:cityOfLiving')}
            disabled={loading}
            value={cityOfLiving}
            onChange={e => setCityOfLiving(e.target.value)}
          />
          <InputRightElement>
            <Button size="sm" variant="plain" onClick={handleInputClear(setCityOfLiving)} isLoading={loading}>
              <SmallCloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>

        <InputGroup size="md">
          <Input
            placeholder={t('user:selectionCountry')}
            disabled={loading}
            value={locationCountry}
            onChange={e => setLocationCountry(e.target.value)}
          />
          <InputRightElement>
            <Button size="sm" variant="plain" onClick={handleInputClear(setLocationCountry)} isLoading={loading}>
              <SmallCloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <br />
      <Box display="flex" mb={2}>Даты регистрации</Box>
      <Box display="flex">
        <InputGroup size="md" mr="10px">
          <Input
            type="date"
            placeholder="Дата регистрации ( ОТ )"
            disabled={loading}
            value={createdAtFrom}
            onChange={e => setCreatedAtFrom(e.target.value)}
          />
          <InputRightElement>
            <Button size="sm" variant="plain" onClick={handleInputClear(setCreatedAtFrom)} isLoading={loading}>
              <SmallCloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>

        <InputGroup size="md">
          <Input
            type="date"
            placeholder="Дата регистрации ( ДО )"
            disabled={loading}
            value={createdAtTo}
            onChange={e => setCreatedAtTo(e.target.value)}
          />
          <InputRightElement>
            <Button size="sm" variant="plain" onClick={handleInputClear(setCreatedAtTo)} isLoading={loading}>
              <SmallCloseIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <br />

      <Box>
        <Button
          colorScheme="teal"
          onClick={handleSearchAll}
          isLoading={loading}
          mr={4}
        >
          Применить фильтры
        </Button>
        <Button
          colorScheme="red"
          onClick={handleResetAll}
          isLoading={loading}
        >
          Сбросить
        </Button>
      </Box>
    </>
  );
};
