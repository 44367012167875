import { useEffect, useMemo } from 'react';

import { Box, Button, Grid, GridItem, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { t } from 'i18next';
import { isEmpty } from 'lodash';

import userStore from 'stores/userStore';
import { IUserPairsBatch } from 'shared/model/offers';
import { formatDate } from 'shared/utils';



export const PairsFilterBar = () => {
  const { fetchPairsBatches, user, fetchPairsBatch, batchDataLoading, userPairsBatches, selectedBatch } = userStore();
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    fetchPairsBatches(user.userId);
  }, []);

  const handleBatchChange = (batch: IUserPairsBatch) => () => {
    fetchPairsBatch(batch);
    onClose();
  };

  const selectedBatchPreferences = useMemo(() => {
    if (isEmpty(selectedBatch) && !selectedBatch.preferences) {
      return null;
    }

    const filteredPreferencesKeys = (Object.keys(selectedBatch.preferences) as Array<keyof typeof selectedBatch.preferences>)
      .filter(key => selectedBatch.preferences[key] !== null
        && selectedBatch.preferences[key] !== undefined
        && selectedBatch.preferences[key] !== ''
        && key !== 'countriesIsoAlpha2'
        && key !== 'countriesIsoAlpha3'
        && key !== 'hasReaction'
        && key !== 'userId'
      );

    console.log('selectedBatch.preferences', selectedBatch.preferences);

    return (filteredPreferencesKeys as Array<keyof typeof selectedBatch.preferences>).map(key => {
      return `${t(`pairBatch:preferences:${String(key)}`)}: ${selectedBatch.preferences[key]}`;
    });
  }, [selectedBatch]);

  // console.log('selectedBatchPreferences', selectedBatchPreferences);

  return (
    <Box display="flex">
      {batchDataLoading ?
        (<p>Loading...</p>)
        : (
          <Box w="100%" mr="10px">
            <Button onClick={onOpen}>Подборы</Button>

            {selectedBatchPreferences && (
              <Grid maxW="full" templateColumns="repeat(auto-fit, minmax(100px, 1fr))" gap={3}>
                {selectedBatchPreferences.map((preference, index) => (
                  <GridItem
                    key={index}
                    as={Box}
                    textAlign="center"
                  >
                    <Text key={preference}>{preference}</Text>
                  </GridItem>
                ))}
              </ Grid >
            )}

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Подборы</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <List spacing={3}>
                    {userPairsBatches.map(batch => {
                      const isSelectedBatch = selectedBatch.id === batch.id;

                      return (
                        <ListItem key={batch.id} w='100%' color='black'>
                          <Button size='md'
                            height='48px'
                            width='200px'
                            border='2px'
                            borderColor={isSelectedBatch ? 'green.500' : 'black'}
                            w="100%"
                            onClick={handleBatchChange(batch)}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <Box>Подбор {formatDate(batch.createdAt)} ({batch.quantity})</Box>
                            {isSelectedBatch && <CheckIcon color="green.500" />}
                          </Button>
                        </ListItem>
                      )
                    }
                    )}
                  </List>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        )
      }
    </Box >
  );
};
