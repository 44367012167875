import React, { useEffect, useMemo, useState } from 'react';

import {
  Avatar,
  Box,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdComment,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Card from 'components/card/Card';

import { IShortOfferItem, IShortOffersList } from '../../../../shared/model/offers';
import TableLoader from '../../../../shared/ui/Table/TableLoader';
import TableNoData from '../../../../shared/ui/Table/TableNoData';

import OfferDetails from './OfferDetails';

const columnHelper = createColumnHelper<IShortOfferItem>();

interface IUserShortOffersTableProps {
  tableData: IShortOffersList;
  loading: boolean;
  error: boolean;
  changePage: (page: number) => void;
  changePerPage: (perPage: number) => void;
}

export default function UserShortOfferTable({
  tableData,
  loading,
  error,
  changePage,
}: // changePerPage,
IUserShortOffersTableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'gray.300');

  const rowColors = {
    '0': useColorModeValue('transparent', 'transparent'),
    '1': useColorModeValue('blackAlpha.50', 'blackAlpha.300'),
    '0hover': useColorModeValue('gray.200', 'blackAlpha.500'),
    '1hover': useColorModeValue('gray.200', 'blackAlpha.500'),
  };

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const { t } = useTranslation('translation');
  const [data, setData] = useState<IShortOfferItem[]>([...(tableData.items || [])]);

  const { isOpen: isDetailsOpen, onOpen: onDetailsOpen, onClose: onDetailsClose } = useDisclosure();
  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);

  useEffect(() => {
    setData([...(tableData.items || [])]);
  }, [tableData.items]);

  const offerDetails = useMemo(() => {
    return (
      <Modal isOpen={isDetailsOpen} onClose={onDetailsClose} blockScrollOnMount={false} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent maxWidth={'50%'} pb={6}>
          <ModalHeader>Подробности</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OfferDetails offerId={selectedOfferId} />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }, [selectedOfferId, isDetailsOpen, onDetailsClose]);

  const columns = [
    columnHelper.accessor('id', {
      id: 'ID',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`user:id`)}
        </Text>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Дата
        </Text>
      ),
      cell: info => {
        return (
          <Flex align="center" direction="row">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('candidate', {
      id: 'candidateName',
      maxSize: 300,
      size: 300,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Имя кандидата
        </Text>
      ),
      cell: info => {
        const { userId, firstName, photo } = info.getValue();

        return (
          <Flex align="center" direction="row">
            <Avatar size="sm" src={photo} mr={3} />
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              overflowWrap="anywhere"
              _hover={{ textDecoration: 'underline' }}
            >
              <Link to={`/users/${userId}`} target="_blank" style={{ overflowWrap: 'anywhere' }}>
                {firstName}
              </Link>
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('compatibility', {
      id: 'compatibility',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Совместимость
        </Text>
      ),
      cell: info => (
        <Flex justify="center" align="centrer" h="100%">
          <Text color={textColor} fontSize="sm" fontWeight="700" alignSelf="center">
            {info.getValue().value}
          </Text>
          <IconButton
            icon={<MdComment />}
            size="sm"
            ml={2}
            aria-label="подробности"
            justifySelf="flex-end"
            onClick={() => {
              setSelectedOfferId(info.row.getValue('ID'));
              onDetailsOpen();
            }}
          />
        </Flex>
      ),
    }),

    columnHelper.accessor('candidateRank', {
      id: 'candidateRank',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Место по баллам
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('searcherRankBeforeReaction', {
      id: 'searcherRankBeforeReaction',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          В рейтинге у<br /> кандидата до реакций
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('searcherReaction', {
      id: 'searcherReaction',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Реакция пользователя
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('candidateReaction', {
      id: 'candidateReaction',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Реакция кандидата
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('searcherRankAfterReaction', {
      id: 'searcherRankAfterReaction',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          В рейтинге у <br /> кандидата после реакций
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('summaryRank', {
      id: 'summaryRank',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Сумма рейтингов
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('resultRank', {
      id: 'resultRank',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          Порядок выдачи анкет
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility: { ID: false, createdAt: false },
    },
    onSortingChange: setSorting,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error) {
    return (
      <Flex justify="center" py={5}>
        <Text>Error</Text>
      </Flex>
    );
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
    >
      <Box>
        <Table variant="textWrap" color="gray.500" mb="24px" mt="12px" layout="auto">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{
                          sm: '10px',
                          lg: '12px',
                        }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {loading ? (
            <TableLoader columnsLength={columns.length} />
          ) : !tableData.items?.length ? (
            <TableNoData columnsLength={columns.length} />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map((row, i) => {
                return (
                  <Tr
                    key={row.id}
                    backgroundColor={rowColors[`${i % 2 ? 0 : 1}`]}
                    sx={{ '&:hover': { backgroundColor: rowColors[`${i % 2 ? 0 : 1}hover`] } }}
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{
                            sm: '150px',
                            md: '200px',
                            lg: 'auto',
                          }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </Box>
      <Divider />
      <Flex justifyContent="space-between" alignItems="center" py={3} px="24px">
        <Box flexGrow={1}>
          <IconButton
            aria-label="First page"
            icon={<MdOutlineKeyboardDoubleArrowLeft />}
            onClick={() => changePage(1)}
            isDisabled={tableData.pagination?.currentPage === 1}
            size="lg"
          />
          <IconButton
            aria-label="prev page"
            icon={<MdOutlineKeyboardArrowLeft />}
            onClick={() => changePage(tableData.pagination?.prevPage)}
            isDisabled={!tableData.pagination?.prevPage}
            size="lg"
          />
          <Box display="inline-block" px={3} color="gray.400">
            {tableData.pagination?.currentPage}
          </Box>
          <IconButton
            aria-label="next page"
            icon={<MdOutlineKeyboardArrowRight />}
            onClick={() => changePage(tableData.pagination?.nextPage)}
            isDisabled={!tableData.pagination?.nextPage}
            size="lg"
          />
          <IconButton
            aria-label="Last page"
            icon={<MdOutlineKeyboardDoubleArrowRight />}
            onClick={() => changePage(tableData.pagination?.lastPage)}
            isDisabled={
              tableData.pagination?.lastPage && tableData.pagination?.currentPage === tableData.pagination?.lastPage
            }
            size="lg"
          />
        </Box>
        {/*
                <Flex direction="row" align="center">
                    <Text color="gray.400" fontSize="sm" fontWeight="700" pr={5}
                          whiteSpace="nowrap">{t('common:items_on_page')}</Text>
                    <Select sx={{'> option': {color: 'gray.400'}}} value={perPage || 20}
                            onChange={(e) => changePerPage(parseInt(e.target.value))}>
                        {[10, 20, 30, 50, 100].map((item) => (
                            <option key={`items-per-page-${item}`} value={item}><Text color="gray.400">{item}</Text>
                            </option>
                        ))}
                    </Select>
                </Flex>
*/}
      </Flex>

      {/*
            <Modal isOpen={isDetailsOpen} onClose={onDetailsClose}  blockScrollOnMount={false}
                   scrollBehavior='inside'>
                <ModalOverlay/>
                <ModalContent maxWidth={'50%'} pb={6}>
                    <ModalHeader>Подробности</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {offerDetails}
                    </ModalBody>
                </ModalContent>
            </Modal>
*/}
      {offerDetails}
    </Card>
  );
}
